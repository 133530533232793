import { Component, Inject, OnDestroy, OnInit }                                from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Data, NavigationEnd, Router } from '@angular/router';
import { DOCUMENT }                                                            from '@angular/common';

import { Observable, Subject }                                        from 'rxjs';
import { distinctUntilKeyChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';

import { AuthUser } from '@models/user/auth-user';
import { Script }   from '@models/utils/script';

import { gtmScript } from '@utils/script/gtm-script';

import { ScriptService }    from '@utils/script/script.service';
import { LayoutService }    from '@layout/layout.service';
import { AnalyticsService } from '@services/analytics.service';
import { UserService }      from '@services/user.service';

declare const RybenaDOM: any;


@Component({
  selector:    'red-root',
  templateUrl: './app.component.html',
  styleUrls:   [ './app.component.scss' ]
})
export class AppComponent implements OnInit, OnDestroy {

  private readonly unsubscribe$: Subject<void>;

  private routeData$:        Observable<Data>;
  private activeUser$:       Observable<AuthUser>;
  private uniqueNavigation$: Observable<NavigationEnd>;

  public document: Document;

  constructor(
    private router:           Router,
    private activatedRoute:   ActivatedRoute,
    private scriptService:    ScriptService,
    private analyticsService: AnalyticsService,
    private layoutService:    LayoutService,
    private userService:      UserService,

    @Inject(DOCUMENT) private _document: any
  ) {
    this.unsubscribe$ = new Subject<void>();
    this.activeUser$  = this.userService.getActiveUser();
    this.document     = this._document as Document;
  }

  private static mapChildRouteData(route: ActivatedRouteSnapshot): Data {
    while (route.firstChild) { route = route.firstChild; }
    return route.data;
  }

  public ngOnInit(): void {
    this.initRouterObservables();
    this.loadRybena();
    this.loadScript({ name: 'gtm' }, gtmScript).subscribe();
    this.userService.initUser().subscribe();
  }

  private initRouterObservables() {
    this.uniqueNavigation$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilKeyChanged<NavigationEnd>('urlAfterRedirects'),
      takeUntil(this.unsubscribe$),
    );

    this.routeData$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => AppComponent.mapChildRouteData(this.activatedRoute.snapshot)),
      takeUntil(this.unsubscribe$),
    );

    this.handleSiteSections();
    this.handlePageViews();
    this.handleLoginExceptions();
  }

  private handleSiteSections() {
    this.routeData$.subscribe(data => this.layoutService.setSiteSection(data['section']));
  }

  private handlePageViews() {
    this.uniqueNavigation$.pipe(
      switchMap(() => this.scriptService.scriptReady('gtm')),
    ).subscribe(() => this.analyticsService.pageView());
  }

  // verify if user needs to change passwords and/or accept contract
  private handleLoginExceptions() {
    const filterLoginExceptions = (user: AuthUser): boolean => {
      if (!user) return false;
      return user.alterarSenha || (user.aceiteContrato && !this.userService.contractAccepted);
    };

    this.uniqueNavigation$.pipe(
      filter((event: NavigationEnd) => !event.urlAfterRedirects.includes('/home')),
      switchMap(() => this.activeUser$),
      filter(filterLoginExceptions)
    ).subscribe((user: AuthUser) => {

      if (user.aceiteContrato) {
        this.router.navigate([ '/home' ],
          { queryParams: { contrato: true } },
        ).then();
      } else if (user.alterarSenha) {
        this.router.navigate([ '/home' ],
          { queryParams: { trocarSenha: true } },
        ).then();
      }

    });
  }

  private loadRybena() {
    const rybenaScript: Script = {
      name: 'rybena',
      src:  'https://cdn.rybena.com.br/dom/master/latest/rybena.js?positionBar=right&positionPlayer=right&backgroundColorBar=#1d2c3d',
    };

    const rybenaCallback = (script: Script) => {
      if (script.loaded) RybenaDOM.getInstance();
      if (script.error)  console.error(script.error);
    };

    this.loadScript(rybenaScript)
        .pipe(filter((script: Script) => Boolean(script)))
        .subscribe(rybenaCallback);
  }

  private loadScript(
    config:   Script,
    content?: string
  ): Observable<Script> {
    const scriptDefaults = { defer: true, async: true };

    if (content) this.scriptService.registerScriptContent(config.name, content);

    return this.scriptService.load({ ...scriptDefaults, ...config });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.layoutService.completeObservables();
  }
}
