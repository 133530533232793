import { NgModule }     from '@angular/core';
import { CommonModule } from '@angular/common';

import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// brands
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faInstagram }      from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faLinkedin }       from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faYoutube }        from '@fortawesome/free-brands-svg-icons/faYoutube';

// regular
import { faCheckSquare as farCheckSquare }       from '@fortawesome/free-regular-svg-icons/faCheckSquare';
import { faSquare as farSquare }                 from '@fortawesome/free-regular-svg-icons/faSquare';
import { faCopy as farCopy }                     from '@fortawesome/free-regular-svg-icons/faCopy';
import { faQuestionCircle as farQuestionCircle } from '@fortawesome/free-regular-svg-icons/faQuestionCircle';

// solid
import { faAngleDown }           from '@fortawesome/free-solid-svg-icons/faAngleDown';
import { faBuilding }            from '@fortawesome/free-solid-svg-icons/faBuilding';
import { faChartLine }           from '@fortawesome/free-solid-svg-icons/faChartLine';
import { faCheckCircle }         from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faMoneyBillAlt }        from '@fortawesome/free-solid-svg-icons/faMoneyBillAlt';
import { faFileAlt }             from '@fortawesome/free-solid-svg-icons/faFileAlt';
import { faFile }                from '@fortawesome/free-solid-svg-icons/faFile';
import { faCog }                 from '@fortawesome/free-solid-svg-icons/faCog';
import { faPhone }               from '@fortawesome/free-solid-svg-icons/faPhone';
import { faSpellCheck }          from '@fortawesome/free-solid-svg-icons/faSpellCheck';
import { faTachometerAlt }       from '@fortawesome/free-solid-svg-icons/faTachometerAlt';
import { faChalkboardTeacher }   from '@fortawesome/free-solid-svg-icons/faChalkboardTeacher';
import { faExclamationCircle }   from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faInfoCircle }          from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faCheck }               from '@fortawesome/free-solid-svg-icons/faCheck';
import { faSquare }              from '@fortawesome/free-solid-svg-icons/faSquare';
import { faTimes }               from '@fortawesome/free-solid-svg-icons/faTimes';
import { faUserCircle }          from '@fortawesome/free-solid-svg-icons/faUserCircle';
import { faExpand }              from '@fortawesome/free-solid-svg-icons/faExpand';
import { faCompress }            from '@fortawesome/free-solid-svg-icons/faCompress';
import { faArrowAltCircleDown }  from '@fortawesome/free-solid-svg-icons/faArrowAltCircleDown';
import { faRedo }                from '@fortawesome/free-solid-svg-icons/faRedo';
import { faUndo }                from '@fortawesome/free-solid-svg-icons/faUndo';
import { faImage }               from '@fortawesome/free-solid-svg-icons/faImage';
import { faSignInAlt }           from '@fortawesome/free-solid-svg-icons/faSignInAlt';
import { faPlayCircle }          from '@fortawesome/free-solid-svg-icons/faPlayCircle';
import { faFileSignature }       from '@fortawesome/free-solid-svg-icons/faFileSignature';
import { faPaperPlane }          from '@fortawesome/free-solid-svg-icons/faPaperPlane';
import { faChartBar }            from '@fortawesome/free-solid-svg-icons/faChartBar';
import { faBars }                from '@fortawesome/free-solid-svg-icons/faBars';
import { faMobileAlt }           from '@fortawesome/free-solid-svg-icons/faMobileAlt';
import { faPhoneAlt }            from '@fortawesome/free-solid-svg-icons/faPhoneAlt';
import { faUser }                from '@fortawesome/free-solid-svg-icons/faUser';
import { faSave }                from '@fortawesome/free-solid-svg-icons/faSave';
import { faHistory }             from '@fortawesome/free-solid-svg-icons/faHistory';
import { faTable }               from '@fortawesome/free-solid-svg-icons/faTable';
import { faSearch }              from '@fortawesome/free-solid-svg-icons/faSearch';
import { faCopy }                from '@fortawesome/free-solid-svg-icons/faCopy';
import { faLongArrowAltUp }      from '@fortawesome/free-solid-svg-icons/faLongArrowAltUp';
import { faLongArrowAltDown }    from '@fortawesome/free-solid-svg-icons/faLongArrowAltDown';
import { faUserGraduate }        from '@fortawesome/free-solid-svg-icons/faUserGraduate';
import { faEye }                 from '@fortawesome/free-solid-svg-icons/faEye';
import { faEdit }                from '@fortawesome/free-solid-svg-icons/faEdit';
import { faTimesCircle }         from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faUpload }              from '@fortawesome/free-solid-svg-icons/faUpload';
import { faPrint }               from '@fortawesome/free-solid-svg-icons/faPrint';
import { faFileExcel }           from '@fortawesome/free-solid-svg-icons/faFileExcel';
import { faPlus }                from '@fortawesome/free-solid-svg-icons/faPlus';
import { faStar }                from '@fortawesome/free-solid-svg-icons/faStar';
import { faAngleRight }          from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { faScroll }              from '@fortawesome/free-solid-svg-icons/faScroll';
import { faICursor }             from '@fortawesome/free-solid-svg-icons/faICursor';
import { faMagic }               from '@fortawesome/free-solid-svg-icons/faMagic';
import { faCalendarAlt }         from '@fortawesome/free-solid-svg-icons/faCalendarAlt';
import { faCalendarCheck }       from '@fortawesome/free-solid-svg-icons/faCalendarCheck';
import { faClock }               from '@fortawesome/free-solid-svg-icons/faClock';
import { faTh }                  from '@fortawesome/free-solid-svg-icons/faTh';
import { faThList }              from '@fortawesome/free-solid-svg-icons/faThList';
import { faSyncAlt }             from '@fortawesome/free-solid-svg-icons/faSyncAlt';
import { faTrashAlt }            from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { faQuestionCircle }      from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { faPen }                 from '@fortawesome/free-solid-svg-icons/faPen';
import { faLongArrowAltLeft }    from '@fortawesome/free-solid-svg-icons/faLongArrowAltLeft';
import { faTrophy }              from '@fortawesome/free-solid-svg-icons/faTrophy';
import { faEnvelope }            from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faLevelUpAlt }          from '@fortawesome/free-solid-svg-icons/faLevelUpAlt';
import { faQuestion }            from '@fortawesome/free-solid-svg-icons/faQuestion';
import { faHome }                from '@fortawesome/free-solid-svg-icons/faHome';
import { faPenNib }              from '@fortawesome/free-solid-svg-icons/faPenNib';
import { faPlusSquare }          from '@fortawesome/free-solid-svg-icons/faPlusSquare';
import { faMinusSquare }         from '@fortawesome/free-solid-svg-icons/faMinusSquare';
import { faExternalLinkAlt }     from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faWrench }              from '@fortawesome/free-solid-svg-icons/faWrench';
import { faThumbsUp }            from '@fortawesome/free-solid-svg-icons/faThumbsUp';
import { faThumbsDown }          from '@fortawesome/free-solid-svg-icons/faThumbsDown';
import { faBan }                 from '@fortawesome/free-solid-svg-icons/faBan';
import { faUserSlash }           from '@fortawesome/free-solid-svg-icons/faUserSlash';
import { faEnvelopeOpen }        from '@fortawesome/free-solid-svg-icons/faEnvelopeOpen';
import { faLongArrowAltRight }   from '@fortawesome/free-solid-svg-icons/faLongArrowAltRight';
import { faExchangeAlt }         from '@fortawesome/free-solid-svg-icons/faExchangeAlt';
import { faDownload }            from '@fortawesome/free-solid-svg-icons/faDownload';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faLock }                from '@fortawesome/free-solid-svg-icons/faLock';
import { faBalanceScale }        from '@fortawesome/free-solid-svg-icons/faBalanceScale';
import { faCoins }               from '@fortawesome/free-solid-svg-icons/faCoins';
import { faSmile }               from '@fortawesome/free-solid-svg-icons/faSmile';
import { faFrown }               from '@fortawesome/free-solid-svg-icons/faFrown';
import { faMeh }                 from '@fortawesome/free-solid-svg-icons/faMeh';
import { faLaughWink }           from '@fortawesome/free-solid-svg-icons/faLaughWink';
import { faEyeSlash }            from '@fortawesome/free-solid-svg-icons/faEyeSlash';
import { faFont }                from '@fortawesome/free-solid-svg-icons/faFont';
import { faToggleOff }           from '@fortawesome/free-solid-svg-icons/faToggleOff';
import { faToggleOn }            from '@fortawesome/free-solid-svg-icons/faToggleOn';
import { faDizzy }               from '@fortawesome/free-solid-svg-icons/faDizzy';
import { faAngry }               from '@fortawesome/free-solid-svg-icons/faAngry';
import { faFilePdf }             from '@fortawesome/free-solid-svg-icons/faFilePdf';
import { faBox }                 from '@fortawesome/free-solid-svg-icons/faBox';
import { faBook }                from '@fortawesome/free-solid-svg-icons/faBook';
import { faArrowCircleDown }     from '@fortawesome/free-solid-svg-icons/faArrowCircleDown';
import { faChevronRight }        from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faChevronDown }         from '@fortawesome/free-solid-svg-icons/faChevronDown';


@NgModule({
  declarations: [],
  imports:      [
    CommonModule,
    FontAwesomeModule
  ],
  exports:      [
    FontAwesomeModule
  ]
})
export class IconsModule {
  constructor(library: FaIconLibrary) {

    // solid
    library.addIcons(
      faAngleDown,           faBuilding,    faChartLine,         faCheckCircle,
      faCog,                 faFile,        faFileAlt,           faMoneyBillAlt,
      faPhone,               faSpellCheck,  faTachometerAlt,     faChalkboardTeacher,
      faSquare,              faCheck,       faInfoCircle,        faExclamationCircle,
      faTimes,               faUserCircle,  faExpand,            faCompress,
      faImage,               faUndo,        faRedo,              faArrowAltCircleDown,
      faSignInAlt,           faPlayCircle,  faFileSignature,     faPaperPlane,
      faPhoneAlt,            faMobileAlt,   faBars,              faChartBar,
      faUser,                faSave,        faHistory,           faTable,
      faSearch,              faCopy,        faUserGraduate,      faEye,
      faPrint,               faUpload,      faTimesCircle,       faEdit,
      faFileExcel,           faPlus,        faLongArrowAltUp,    faLongArrowAltDown,
      faStar,                faAngleRight,  faICursor,           faScroll,
      faMagic,               faClock,       faCalendarAlt,       faQuestionCircle,
      faThList,              faSyncAlt,     faTrashAlt,          faLongArrowAltLeft,
      faTh,                  faPen,         faTrophy,            faEnvelope,
      faEnvelopeOpen,        faQuestion,    faHome,              faPenNib,
      faPlusSquare,          faMinusSquare, faExternalLinkAlt,   faWrench,
      faThumbsUp,            faThumbsDown,  faBan,               faUserSlash,
      faLevelUpAlt,          faExchangeAlt, faLongArrowAltRight, faDownload,
      faExclamationTriangle, faLock,        faBalanceScale,      faCoins,
      faSmile,               faFrown,       faMeh,               faLaughWink,
      faEyeSlash,            faFont,        faBox,               faBook,
      faArrowCircleDown,     faDizzy,       faAngry,             faPlusSquare,
      faToggleOff,           faToggleOn,    faFilePdf,           faCalendarCheck,
      faChevronRight,        faChevronDown
    );

    // regular
    library.addIcons(farSquare, farCheckSquare, farCopy, farQuestionCircle);

    // brands
    library.addIcons(faFacebookSquare, faInstagram, faLinkedin, faYoutube);
  }
}
